import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { RouteBackground } from 'components';
import { ScrollBox } from 'common/components';
import { ReactComponent as BackArrowIcon } from 'img/arrow-circle-left.svg';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
  },
  body: {
    flex: 1,
    minHeight: 0,
    width: '100%',
  },
  contentWrapper: {
    flex: 1,
    height: '100%',
    minWidth: 0,
    [theme.breakpoints.down('md')]: {
      minWidth: 'auto',
    },
  },
  sidebarWrapper: {
    height: '100%',
    width: 210,
    minWidth: 210,
    paddingRight: theme.spacing(2),
  },
}));

interface IServicesContainerProps {
  className?: string;
  titleMessage?: { id: string; defaultMessage: string };
  titleComponent?: JSX.Element;
  backRedirectLink?: string;
  children: JSX.Element;
}

export default function ServicesPageWrapper({
  className,
  children,
  titleMessage,
  titleComponent,
  backRedirectLink,
}: IServicesContainerProps): JSX.Element {
  const navigate = useNavigate();

  const classes = useStyles();

  return (
    <RouteBackground hasMobilePadding>
      <Grid container spacing={2} direction="column" className={`${classes.root} ${className}`}>
        <Grid item container spacing={1} justifyContent="space-between">
          <Grid item>
            <Box display="flex" alignItems="center">
              {backRedirectLink && (
                <Box mr={1}>
                  <IconButton style={{ padding: 0 }} onClick={() => navigate(backRedirectLink)}>
                    <BackArrowIcon style={{ width: 17, height: 17 }} />
                  </IconButton>
                </Box>
              )}
              {titleMessage && (
                <Typography variant="h2">
                  <FormattedMessage {...titleMessage} />
                </Typography>
              )}

              {titleComponent && titleComponent}
            </Box>
          </Grid>
        </Grid>
        <Grid item className={classes.body}>
          <ScrollBox suppressScrollX>
            <Box display="flex" style={{ width: '100%', height: '100%' }}>
              <Box className={classes.contentWrapper}>{children}</Box>
            </Box>
          </ScrollBox>
        </Grid>
      </Grid>
    </RouteBackground>
  );
}
