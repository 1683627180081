const colors = {
  cinnamon: '#C63D4D',
  gray50: '#F7F8F8',
  gray100: '#D9DDE5',
  gray150: '#717B8F',
  gray200: '#2C2E31',
  gray300: '#1F2123',
  skyBlue: '#269BD3',
  tableBorder: '#ECECEC',
  tableHeaderFont: '#707A8E',
  gunMetal: '#4e5563',
  lightGray: '#c5c9d1',
  blueGray: '#F7F8F8',
  blueGray100: '#F7FBFF',
  blueRoyal: '#445EAE',
  orange100: '#E98A43',
  orange200: '#EF7621',
  green100: '#25C878',
  green200: '#349252',
  purple: '#AC66BA',
  yellow100: '#E7C46B',
  yellow200: '#E9C843',
  tableRowHover: '',
  teal: '#25C7C8',
  pink: '#BA6675',
  white: '#FFFFFF',
  black: '#000000',
  errorRed: '#cc3300',
  modalBG: 'rgba(44, 46, 49, 0.5)'
};

export { colors };
