import { defineMessages } from 'react-intl';

export default defineMessages({
  // Buttons
  saveBtn: {
    id: 'app.common.buttons.save',
    defaultMessage: 'Save',
  },
  saveContinueBtn: {
    id: 'app.common.buttons.saveContinue',
    defaultMessage: 'Save & Continue',
  },
  continueBtn: {
    id: 'app.common.buttons.continue',
    defaultMessage: 'Continue',
  },
  editBtn: {
    id: 'app.common.buttons.edit',
    defaultMessage: 'Edit',
  },
  reprintBtn: {
    id: 'app.common.buttons.reprint',
    defaultMessage: 'Reprint',
  },
  editProfileBtn: {
    id: 'app.common.buttons.editProfile',
    defaultMessage: 'Edit profile',
  },
  deleteBtn: {
    id: 'app.common.buttons.delete',
    defaultMessage: 'Delete',
  },
  cancelBtn: {
    id: 'app.common.buttons.cancel',
    defaultMessage: 'Cancel',
  },
  discardBtn: {
    id: 'app.common.buttons.discard',
    defaultMessage: 'Discard',
  },
  okBtn: {
    id: 'app.common.buttons.ok',
    defaultMessage: 'Ok',
  },
  skipBtn: {
    id: 'app.common.buttons.skipBtn',
    defaultMessage: 'Skip',
  },
  submitBtn: {
    id: 'app.common.buttons.submit',
    defaultMessage: 'Submit',
  },
  applyBtn: {
    id: 'app.common.buttons.apply',
    defaultMessage: 'Apply',
  },
  newBtn: {
    id: 'app.common.buttons.new',
    defaultMessage: 'New',
  },
  addNewBtn: {
    id: 'app.common.buttons.addNew',
    defaultMessage: 'Add New',
  },
  doneBtn: {
    id: 'app.common.buttons.done',
    defaultMessage: 'Done',
  },
  nextBtn: {
    id: 'app.common.buttons.next',
    defaultMessage: 'Next',
  },
  exportBtn: {
    id: 'app.common.buttons.export',
    defaultMessage: 'Export',
  },
  continueLaterBtn: {
    id: 'app.common.buttons.continue-later',
    defaultMessage: 'CONTINUE LATER',
  },
  proceedBtn: {
    id: 'app.modules.pos.pos-actions.proceedBtn',
    defaultMessage: 'PROCEED',
  },
  backBtn: {
    id: 'app.common.buttons.back',
    defaultMessage: 'Back',
  },
  backToCartBtn: {
    id: 'app.common.buttons.backToCart',
    defaultMessage: 'Back to cart',
  },
  clearBtn: {
    id: 'app.common.buttons.clear',
    defaultMessage: 'Clear',
  },
  notNowBtn: {
    id: 'app.common.buttons.not-now',
    defaultMessage: 'Not now',
  },
  archiveBtn: {
    id: 'app.common.buttons.archive',
    defaultMessage: 'Archive',
  },
  activateBtn: {
    id: 'app.common.buttons.activate',
    defaultMessage: 'Activate',
  },
  deactivateBtn: {
    id: 'app.common.buttons.deactivate',
    defaultMessage: 'Deactivate',
  },
  unArchiveBtn: {
    id: 'app.common.buttons.unarchive',
    defaultMessage: 'Unarchive',
  },
  unredeemBtn: {
    id: 'app.common.buttons.unredeem',
    defaultMessage: 'Unredeem',
  },
  redeemBtn: {
    id: 'app.common.buttons.redeem',
    defaultMessage: 'Redeem',
  },
  freezeBtn: {
    id: 'app.common.buttons.freeze',
    defaultMessage: 'Freeze',
  },
  yesCloseBtn: {
    id: 'app.common.buttons.YesClose',
    defaultMessage: 'Yes, close',
  },
  closeBtn: {
    id: 'app.common.buttons.close',
    defaultMessage: 'Close',
  },
  createBtn: {
    id: 'app.common.buttons.Create',
    defaultMessage: 'Create',
  },
  historyBtn: {
    id: 'app.common.buttons.History',
    defaultMessage: 'History',
  },
  addBtn: {
    id: 'app.common.buttons.add',
    defaultMessage: 'Add',
  },
  showMoreBtn: {
    id: 'app.common.buttons.showMore',
    defaultMessage: 'Show more',
  },
  showLessBtn: {
    id: 'app.common.buttons.showLess',
    defaultMessage: 'Show less',
  },
  copyBtn: {
    id: 'app.common.buttons.copy',
    defaultMessage: 'Copy',
  },
  assignBtn: {
    id: 'app.common.buttons.assign',
    defaultMessage: 'Assign',
  },
  changeBtn: {
    id: 'app.common.buttons.change',
    defaultMessage: 'Change',
  },
  ignoreBtn: {
    id: 'app.common.buttons.Ignore',
    defaultMessage: 'Ignore',
  },
  remindLaterBtn: {
    id: 'app.common.buttons.RemindLater',
    defaultMessage: 'Remind later',
  },
  remindBtn: {
    id: 'app.common.buttons.remind',
    defaultMessage: 'Remind',
  },
  resolveBtn: {
    id: 'app.common.buttons.Resolve',
    defaultMessage: 'Resolve',
  },
  defaultBtn: {
    id: 'app.common.buttons.default',
    defaultMessage: 'DEFAULT',
  },
  customBtn: {
    id: 'app.common.buttons.custom',
    defaultMessage: 'CUSTOM',
  },
  blockBtn: {
    id: 'app.common.buttons.block',
    defaultMessage: 'BLOCK',
  },
  newStepBtn: {
    id: 'app.common.buttons.newStep',
    defaultMessage: 'New step',
  },
  openBtn: {
    id: 'app.common.buttons.openBtn',
    defaultMessage: 'Open',
  },
  removeBtn: {
    id: 'app.common.buttons.remove',
    defaultMessage: 'Remove',
  },
  removeRejectionBtn: {
    id: 'app.common.buttons.removeRejection',
    defaultMessage: 'Remove rejection',
  },
  duplicateButton: {
    id: 'app.common.buttons.duplicateButton',
    defaultMessage: 'Duplicate',
  },
  checkInBtn: {
    id: 'app.common.invoiceOperating.checkInBtn',
    defaultMessage: 'Check-in',
  },
  availabilityBtn: {
    id: 'app.common.buttons.availability',
    defaultMessage: 'Availability',
  },
  daysTimeBtn: {
    id: 'app.common.buttons.daysTime',
    defaultMessage: 'Days, Time',
  },
  sellBtn: {
    id: 'app.common.buttons.sell',
    defaultMessage: 'Sell',
  },
  sendBtn: {
    id: 'app.common.buttons.send',
    defaultMessage: 'Send',
  },
  goBackBtn: {
    id: 'app.common.buttons.goBackBtn',
    defaultMessage: 'Go back',
  },
  browseBtn: {
    id: 'app.common.buttons.browseBtn',
    defaultMessage: 'Browse',
  },
  newAlertBtn: {
    id: 'app.common.buttons.newAlertBtn',
    defaultMessage: 'New Alert',
  },
  writeOffBtn: {
    id: 'app.common.buttons.writeOffBtn',
    defaultMessage: 'Write off',
  },
  scanFromMagneticStripeBtn: {
    id: 'app.common.buttons.scanFromMagneticStripeBtn',
    defaultMessage: 'Scan From Magnetic Stripe',
  },
  takePhotoBtn: {
    id: 'app.common.buttons.takePhotoBtn',
    defaultMessage: 'Take a photo',
  },
  browseForFilesBtn: {
    id: 'app.common.buttons.browseForFilesBtn',
    defaultMessage: 'Browse for files',
  },
  scanDocumentBtn: {
    id: 'app.common.buttons.scanDocumentBtn',
    defaultMessage: 'Scan document',
  },
  printBtn: {
    id: 'app.common.buttons.printBtn',
    defaultMessage: 'Print',
  },
  duplicatedEventBtn: {
    id: 'app.common.buttons.duplicatedEventBtn',
    defaultMessage: 'Duplicated Event',
  },
  connectBtn: {
    id: 'app.common.buttons.connectBtn',
    defaultMessage: 'Connect',
  },
  addPhoneBtn: {
    id: 'app.common.buttons.addPhoneBtn',
    defaultMessage: 'Add Phone',
  },
  resetConfigBtn: {
    id: 'app.common.buttons.resetConfigBtn',
    defaultMessage: 'Reset Configuration',
  },
  onBtn: {
    id: 'app.common.buttons.onBtn',
    defaultMessage: 'On',
  },
  offBtn: {
    id: 'app.common.buttons.offBtn',
    defaultMessage: 'Off',
  },
  addMembershipBtn: {
    id: 'app.common.buttons.addMembership',
    defaultMessage: 'Add Membership',
  },
  configureBtn: {
    id: 'app.common.buttons.configure',
    defaultMessage: 'Configure',
  },
  confirmBtn: {
    id: 'app.common.buttons.confirm',
    defaultMessage: 'Confirm',
  },
  entranceBtn: {
    id: 'app.common.buttons.entrance',
    defaultMessage: 'Entrance',
  },
  checkInManually: {
    id: 'app.common.buttons.checkInManually',
    defaultMessage: 'Check-in Manually',
  },
  lockBtn: {
    id: 'app.common.buttons.lock',
    defaultMessage: 'Lock',
  },
  unlockBtn: {
    id: 'app.common.buttons.unlock',
    defaultMessage: 'Unlock',
  },
  downloadBtn: {
    id: 'app.common.buttons.download',
    defaultMessage: 'Download',
  },
  viewChangesBtn: {
    id: 'app.common.buttons.viewChanges',
    defaultMessage: 'View changes',
  },
  checkInAllBtn: {
    id: 'app.common.buttons.checkInAll',
    defaultMessage: 'Check-in All',
  },
  broughtBtn: {
    id: 'app.common.buttons.brought',
    defaultMessage: 'Brought',
  },
  addCardBtn: {
    id: 'app.common.buttons.addCard',
    defaultMessage: 'Add Card',
  },
  createNewOneBtn: {
    id: 'app.common.buttons.createNewOne',
    defaultMessage: 'Create new one',
  },
  createNewBtn: {
    id: 'app.common.buttons.createNew',
    defaultMessage: 'Create new',
  },
  noShownBtn: {
    id: 'app.common.buttons.noShow',
    defaultMessage: 'No Show',
  },
  viewNoteBtn: {
    id: 'app.common.buttons.viewNote',
    defaultMessage: 'View Note',
  },
  confirmAndCancelBtn: {
    id: 'app.common.buttons.confirmAndCancel',
    defaultMessage: 'Confirm & cancel',
  },
  proceedToPaymentBtn: {
    id: 'app.common.buttons.proceedToPayment',
    defaultMessage: 'Proceed to payment',
  },
  reactivateBtn: {
    id: 'app.common.buttons.reactivate',
    defaultMessage: 'Reactivate',
  },
  completeBtn: {
    id: 'app.common.buttons.complete',
    defaultMessage: 'Complete',
  },
  clockInBtn: {
    id: 'app.common.buttons.clockIn',
    defaultMessage: 'Clock In',
  },
  clockOutBtn: {
    id: 'app.common.buttons.clockOutBtn',
    defaultMessage: 'Clock Out',
  },
  trackBtn: {
    id: 'app.common.buttons.track',
    defaultMessage: 'Track',
  },
  changeRegisterBtn: {
    id: 'app.common.buttons.changeRegister',
    defaultMessage: 'Change Register',
  },
  viewTransactionsBtn: {
    id: 'app.common.buttons.viewTransactions',
    defaultMessage: 'View transactions',
  },
  // options
  yesOption: {
    id: 'app.common.options.yes',
    defaultMessage: 'Yes',
  },
  noOption: {
    id: 'app.common.options.no',
    defaultMessage: 'No',
  },
  allOption: {
    id: 'app.common.options.all',
    defaultMessage: 'All',
  },
  noneOption: {
    id: 'app.common.options.none',
    defaultMessage: 'None',
  },
  activeOption: {
    id: 'app.common.options.active',
    defaultMessage: 'Active',
  },
  activateItem: {
    id: 'app.common.options.activateItem',
    defaultMessage: 'Activate {name}?',
  },
  deactivateItem: {
    id: 'app.common.options.deactivate.item',
    defaultMessage: 'Deactivate {name}?',
  },
  duplicateOption: {
    id: 'app.common.options.duplicate',
    defaultMessage: 'Duplicate',
  },
  inactiveOption: {
    id: 'app.common.options.inactive',
    defaultMessage: 'Inactive',
  },
  archivedOption: {
    id: 'app.common.options.archived',
    defaultMessage: 'Archived',
  },
  canceledOption: {
    id: 'app.common.options.canceled',
    defaultMessage: 'Canceled',
  },
  plannedOption: {
    id: 'app.common.options.planned',
    defaultMessage: 'Planned',
  },
  completedOption: {
    id: 'app.common.options.completed',
    defaultMessage: 'Completed',
  },
  configurationOption: {
    id: 'app.common.options.configurationOption',
    defaultMessage: 'Edit',
  },
  homeOption: {
    id: 'app.common.options.homeOption',
    defaultMessage: 'Home',
  },
  workOption: {
    id: 'app.common.options.workOption',
    defaultMessage: 'Work',
  },
  cellOption: {
    id: 'app.common.options.cellOption',
    defaultMessage: 'Cell',
  },
  inUseOption: {
    id: 'app.common.options.inUseOption',
    defaultMessage: 'In Use',
  },
  notInUse: {
    id: 'app.common.options.notInUse',
    defaultMessage: 'Not in use',
  },
  viewMemberProfile: {
    id: 'app.common.options.viewMemberProfile',
    defaultMessage: 'View Member profile',
  },
  // labels
  multiTitlesLabel: {
    id: 'app.common.labels.multiTitleLabel',
    defaultMessage: '{firstTitles} and {moreCount} more',
  },
  phoneLabel: {
    id: 'app.common.labels.phone',
    defaultMessage: 'Phone',
  },
  emailLabel: {
    id: 'app.common.labels.email',
    defaultMessage: 'Email',
  },
  emailsLabel: {
    id: 'app.common.labels.emails',
    defaultMessage: 'Emails',
  },
  attachmentsLabel: {
    id: 'app.common.labels.attachments',
    defaultMessage: 'Attachments',
  },
  smsLabel: {
    id: 'app.common.labels.sms',
    defaultMessage: 'SMS',
  },
  callsLabel: {
    id: 'app.common.labels.calls',
    defaultMessage: 'Calls',
  },
  notificationLabel: {
    id: 'app.common.labels.notification',
    defaultMessage: 'Notification',
  },
  codeLabel: {
    id: 'app.common.labels.code',
    defaultMessage: 'Code',
  },
  clubsLabel: {
    id: 'app.common.labels.clubs',
    defaultMessage: 'Clubs',
  },
  clubLabel: {
    id: 'app.common.labels.club',
    defaultMessage: 'Club',
  },
  revenueCodeLabel: {
    id: 'app.common.labels.revenueCode',
    defaultMessage: 'Revenue Code',
  },
  hourlyLabel: {
    id: 'app.common.labels.hourly',
    defaultMessage: 'Hourly',
  },
  salaryLabel: {
    id: 'app.common.labels.salary',
    defaultMessage: 'Salary',
  },
  unitLabel: {
    id: 'app.common.labels.unit',
    defaultMessage: 'Unit',
  },
  emailTemplateLabel: {
    id: 'app.common.labels.emailTemplate',
    defaultMessage: 'Email template',
  },
  singleLabel: {
    id: 'app.common.labels.single',
    defaultMessage: 'Single',
  },
  marriedLabel: {
    id: 'app.common.labels.married',
    defaultMessage: 'Married',
  },
  widowedLabel: {
    id: 'app.common.labels.widowed',
    defaultMessage: 'Widowed',
  },
  divorcedLabel: {
    id: 'app.common.labels.divorced',
    defaultMessage: 'Divorced',
  },
  employeeLabel: {
    id: 'app.common.labels.employee',
    defaultMessage: 'Employee',
  },
  logoLabel: {
    id: 'app.common.labels.logo',
    defaultMessage: 'Logo',
  },
  brandingLabel: {
    id: 'app.common.labels.branding',
    defaultMessage: 'Branding',
  },
  primaryColorLabel: {
    id: 'app.common.labels.primaryColor',
    defaultMessage: 'Primary color',
  },
  userId: {
    id: 'app.common.labels.userId',
    defaultMessage: 'User ID: {userId}',
  },
  pointOfContactLabel: {
    id: 'app.common.labels.pointOfContact',
    defaultMessage: 'Point of contact',
  },
  ownerAccountLabel: {
    id: 'app.common.labels.ownerAccount',
    defaultMessage: 'Club Owner',
  },
  trainersLabel: {
    id: 'app.common.labels.trainers',
    defaultMessage: 'Trainers',
  },
  utcLabel: {
    id: 'app.common.labels.utcLabel',
    defaultMessage: 'UTC',
  },
  gmtLabel: {
    id: 'app.common.labels.gmtLabel',
    defaultMessage: 'GMT',
  },
  sidLabel: {
    id: 'app.common.labels.sidLabel',
    defaultMessage: 'SID',
  },
  uidLabel: {
    id: 'app.common.labels.uidLabel',
    defaultMessage: 'UID',
  },
  tokenLabel: {
    id: 'app.common.labels.tokenLabel',
    defaultMessage: 'Token',
  },
  customerFieldLabel: {
    id: 'app.common.labels.customerField',
    defaultMessage: 'Customer data field',
  },
  clubFieldLabel: {
    id: 'app.common.labels.clubField',
    defaultMessage: 'Club data field',
  },
  signatureBlockLabel: {
    id: 'app.common.labels.signatureBlock',
    defaultMessage: 'Signature block',
  },
  physicalAddressLabel: {
    id: 'app.common.labels.physicalAddressLabel',
    defaultMessage: 'Physical Address',
  },
  mailingAddressLabel: {
    id: 'app.common.labels.mailingAddressLabel',
    defaultMessage: 'Mailing Address',
  },
  processorConfigurationLabel: {
    id: 'app.common.labels.processorConfigurationLabel',
    defaultMessage: 'Processor Configuration',
  },
  localLabel: {
    id: 'app.common.labels.localLabel',
    defaultMessage: 'Local',
  },
  reasonLabel: {
    id: 'app.common.labels.reasonLabel',
    defaultMessage: 'Reason: {reason}',
  },
  signInOptionsLabel: {
    id: 'app.common.labels.signInOptionsLabel',
    defaultMessage: 'Sign-in options',
  },
  unlimitedLabel: {
    id: 'app.common.labels.unlimitedLabel',
    defaultMessage: 'Unlimited',
  },
  limitedLabel: {
    id: 'app.common.labels.limitedLabel',
    defaultMessage: 'Limited',
  },
  generalLabel: {
    id: 'app.common.labels.generalLabel',
    defaultMessage: 'General',
  },
  clubsAvailabilityLabel: {
    id: 'app.common.labels.clubsAvailabilityLabel',
    defaultMessage: 'Availability in clubs',
  },
  withoutDOBLabel: {
    id: 'app.common.labels.withoutDOBLabel',
    defaultMessage: 'Without DOB',
  },
  cashLabel: {
    id: 'app.common.labels.cash',
    defaultMessage: 'Cash',
  },
  onAccountLabel: {
    id: 'app.common.labels.onAccount',
    defaultMessage: 'On Account',
  },
  tabOnCorporateAccountLabel: {
    id: 'app.common.labels.tabOnCorporateAccount',
    defaultMessage: 'Tab on a corporate account',
  },
  memberRewardsLabel: {
    id: 'app.common.labels.memberRewards',
    defaultMessage: 'Member rewards',
  },
  clientRewardsLabel: {
    id: 'app.common.labels.clientRewards',
    defaultMessage: 'Client Rewards',
  },
  checkLabel: {
    id: 'app.common.labels.check',
    defaultMessage: 'Check',
  },
  giftCardLabel: {
    id: 'app.common.labels.giftCard',
    defaultMessage: 'Gift Card, Bonus Points, ets.',
  },
  creditCardLabel: {
    id: 'app.common.labels.creditCardLabel',
    defaultMessage: 'Credit Card',
  },
  emptyHomeClubLabel: {
    id: 'app.common.labels.emptyHomeClub',
    defaultMessage: 'Empty Home Club',
  },
  dayLabel: {
    id: 'app.common.labels.day',
    defaultMessage: 'Day',
  },
  weekLabel: {
    id: 'app.common.labels.week',
    defaultMessage: 'Week',
  },
  monthLabel: {
    id: 'app.common.labels.month',
    defaultMessage: 'Month',
  },
  yearLabel: {
    id: 'app.common.labels.year',
    defaultMessage: 'Year',
  },
  automaticLabel: {
    id: 'app.common.labels.automatic',
    defaultMessage: 'Automatic',
  },
  manualLabel: {
    id: 'app.common.labels.manual',
    defaultMessage: 'Manual',
  },
  matchingToCriteriaLabel: {
    id: 'app.common.labels.matchingToCriteria',
    defaultMessage: 'Matching to criteria',
  },
  disabledLabel: {
    id: 'app.common.labels.disabledLabel',
    defaultMessage: 'Disabled',
  },
  billingLabel: {
    id: 'app.common.labels.billing',
    defaultMessage: 'Billing',
  },
  bundleLabel: {
    id: 'app.common.labels.bundle',
    defaultMessage: 'Bundle',
  },
  feeLabel: {
    id: 'app.common.labels.fee',
    defaultMessage: 'Fee',
  },
  serviceLabel: {
    id: 'app.common.labels.service',
    defaultMessage: 'Service',
  },
  childLabel: {
    id: 'app.common.labels.child',
    defaultMessage: 'Child',
  },
  // titles
  addClubTitle: {
    id: 'app.common.titles.addClub',
    defaultMessage: 'Add Club',
  },
  certificatesPerCurrentPC: {
    id: 'app.common.titles.certificatesPerCurrentPC',
    defaultMessage: 'Certificates per current PC',
  },
  qzGenerateCertificates: {
    id: 'app.common.titles.qzGenerateCertificates',
    defaultMessage:
      'Please generate certificates via QZ desktop application and enter content into fields',
  },
  skipRecommendedFields: {
    id: 'app.common.titles.skipRecommendedFields',
    defaultMessage: 'Some of recommended fields are not set, please set them or skip',
  },
  saveConfirmation: {
    id: 'app.common.titles.saveConfirmation',
    defaultMessage: 'Save confirmation',
  },
  chooseClubTitle: {
    id: 'app.common.titles.chooseClub',
    defaultMessage: 'Choose a Club',
  },
  addClubsTitle: {
    id: 'app.common.titles.addClubs',
    defaultMessage: 'Add clubs',
  },
  deleteClubTitle: {
    id: 'app.common.titles.deleteClub',
    defaultMessage: 'Delete Club',
  },
  deleteClubsTitle: {
    id: 'app.common.titles.deleteClubs',
    defaultMessage: 'Delete Clubs',
  },
  permissionLevelTitle: {
    id: 'app.common.titles.permissionLevel',
    defaultMessage: 'Permission level',
  },
  clubTitle: {
    id: 'app.common.titles.club',
    defaultMessage: 'Club',
  },
  corporateTitle: {
    id: 'app.common.titles.corporate',
    defaultMessage: 'Corporate',
  },
  peakTitle: {
    id: 'app.common.titles.peak',
    defaultMessage: 'Peak',
  },
  statusTitle: {
    id: 'app.common.titles.status',
    defaultMessage: 'Status',
  },
  paymentType: {
    id: 'app.common.titles.paymentType',
    defaultMessage: 'Payment type',
  },
  payment: {
    id: 'app.common.titles.payment',
    defaultMessage: 'Payment',
  },
  internalStatusTypeTitle: {
    id: 'app.common.titles.internalStatusType',
    defaultMessage: 'Type',
  },
  openStatusTitle: {
    id: 'app.common.titles.open',
    defaultMessage: 'Open/Closed',
  },
  reportTypeTitle: {
    id: 'app.common.titles.reportType',
    defaultMessage: 'Report type',
  },
  reportingToken: {
    id: 'app.common.titles.reportingToken',
    defaultMessage: 'Reporting Token',
  },
  customTitle: {
    id: 'app.common.titles.custom',
    defaultMessage: 'Custom',
  },
  defaultTitle: {
    id: 'app.common.titles.default',
    defaultMessage: 'Default',
  },
  usedTitle: {
    id: 'app.common.titles.used',
    defaultMessage: 'Used',
  },
  blockTitle: {
    id: 'app.common.titles.block',
    defaultMessage: 'Block',
  },
  blockedTitle: {
    id: 'app.common.titles.blocked',
    defaultMessage: 'Blocked',
  },
  scanBarcodeTitle: {
    id: 'app.common.titles.scanBarcode',
    defaultMessage: 'SCAN BARCODE',
  },
  entrancesTitle: {
    id: 'app.common.titles.entrancesTitle',
    defaultMessage: 'Entrances',
  },
  helpTitle: {
    id: 'app.common.titles.helpTitle',
    defaultMessage: 'Help',
  },
  contactPeak: {
    id: 'app.common.titles.contactPeak',
    defaultMessage: 'Contact Peak',
  },
  customerService: {
    id: 'app.common.titles.contactService',
    defaultMessage: 'Customer Service',
  },
  memberSupport: {
    id: 'app.common.titles.memberSupport',
    defaultMessage: 'Member Support',
  },
  checkinHistoryTitle: {
    id: 'app.common.titles.checkinHistoryTitle',
    defaultMessage: 'Check-in History',
  },
  expired: {
    id: 'app.common.titles.expired',
    defaultMessage: 'Expired',
  },
  integrations: {
    id: 'app.common.titles.integrations',
    defaultMessage: 'Integrations',
  },
  linkToReportTitle: {
    id: 'app.common.titles.linkToReport',
    defaultMessage: 'Link to report',
  },
  noActiveMembershipTitle: {
    id: 'app.common.titles.linkToReport',
    defaultMessage: 'No Active Membership',
  },
  // bodies
  questionDeleteClubBody: {
    id: 'app.common.bodies.questionDeleteClub',
    defaultMessage: 'Are you sure you want to delete the club',
  },
  questionDeleteClubsBody: {
    id: 'app.common.bodies.questionDeleteClubs',
    defaultMessage: 'Are you sure you want to delete the {value} clubs',
  },
  newBody: {
    id: 'app.common.bodies.new',
    defaultMessage: 'New {value}',
  },
  idBody: {
    id: 'app.common.bodies.id',
    defaultMessage: 'ID: {value}',
  },
  uploadFieldBody: {
    id: 'app.common.bodies.uploadFieldBody',
    defaultMessage: 'Drag & Drop to upload or',
  },
  uploadFieldBodyFirstPart: {
    id: 'app.common.bodies.uploadFieldBodyFirstPart',
    defaultMessage: 'Drag & Drop',
  },
  uploadFieldBodySecondPart: {
    id: 'app.common.bodies.uploadFieldBodySecondPart',
    defaultMessage: 'to upload or',
  },
  questionDeletePhotoBody: {
    id: 'app.common.bodies.questionDeleteImage',
    defaultMessage: 'You are about to delete the photo. You are sure?',
  },
  deletePhotoTitle: {
    id: 'app.common.bodies.deletePhotoTitle',
    defaultMessage: 'Delete Photo',
  },
  hiBody: {
    id: 'app.common.bodies.hi',
    defaultMessage: 'Hi, {name} ',
  },
  enteredSymbolsBody: {
    id: 'app.common.bodies.enteredSymbols',
    defaultMessage: '{enteredSymbolsCount}/{symbolsCount} symbols',
  },
  timezoneIsDifferentBody: {
    id: 'app.modules.bodies.timezoneIsDifferent',
    defaultMessage:
      "Your timezone view {timezoneViewOffset} is different from club's timezone {clubZoneOffset}. Days & hours for access displayed in club timezone.",
  },
  // invoice operating
  notInSystemInvoiceWarning: {
    id: 'app.common.invoiceOperating.notInSystemInvoiceWarning',
    /* eslint-disable no-template-curly-in-string */
    defaultMessage: 'You’ve exceeded Not in System invoice limitations (${limit}).',
  },
  notInSystemInvoiceWarningDescription: {
    id: 'app.common.invoiceOperating.notInSystemInvoiceWarningDescription',
    defaultMessage:
      'Before checkout you need to decrease total invoice amount or enter new lead information',
  },
  selectCustomerWarningDescription: {
    id: 'app.common.invoiceOperating.selectCustomerWarningDescription',
    defaultMessage:
      'Before adding bundle or account credit balance into invoice cart you need to select customer or enter new guest\\member information.',
  },
  cancelInvoice: {
    id: 'app.common.invoiceOperating.cancel',
    defaultMessage: 'Cancel Invoice',
  },
  voidInvoice: {
    id: 'app.common.invoiceOperating.void',
    defaultMessage: 'Void Invoice',
  },
  voidBtn: {
    id: 'app.common.invoiceOperating.voidBtn',
    defaultMessage: 'Void',
  },
  addPayMethod: {
    id: 'app.common.invoiceOperating.addPayMethod',
    defaultMessage: 'Add Payment Method',
  },
  checkOut: {
    id: 'app.common.invoiceOperating.checkout',
    defaultMessage: 'Checkout',
  },
  items: {
    id: 'app.common.invoiceOperating.items',
    defaultMessage: 'Items',
  },
  services: {
    id: 'app.common.invoiceOperating.services',
    defaultMessage: 'Services',
  },
  fees: {
    id: 'app.common.invoiceOperating.fees',
    defaultMessage: 'Fees',
  },
  tab: {
    id: 'app.common.invoiceOperating.tab',
    defaultMessage: 'Tab',
  },
  topUp: {
    id: 'app.common.invoiceOperating.topUp',
    defaultMessage: 'Account Credit',
  },
  giftCard: {
    id: 'app.common.invoiceOperating.giftCard',
    defaultMessage: 'Gift Card',
  },
  tips: {
    id: 'app.common.invoiceOperating.tips',
    defaultMessage: 'Tips',
  },
  emptyCart: {
    id: 'app.common.invoiceOperating.emptyCart',
    defaultMessage: 'Cart is Empty',
  },
  noRegistersText: {
    id: 'app.common.invoiceOperating.noRegistersText',
    defaultMessage: 'No registers found',
  },
  makeFirstPaymentBody: {
    id: 'app.common.invoiceOperating.makeFirstPaymentBody',
    defaultMessage: 'Do you want to make first payment now?',
  },
  paymentGraceMightBePaidBody: {
    id: 'app.common.invoiceOperating.paymentGraceMightBePaidBody',
    defaultMessage:
      'Payment grace allowed, ${price} might be paid during next {durationNumber} {duration}.',
  },
  feeWillBeChargedBody: {
    id: 'app.common.invoiceOperating.feeWillBeChargedBody',
    defaultMessage: '{price} will be charged after {period} days',
  },
  haveNoPermissions: {
    id: 'app.common.timeclock.haveNoPermissions',
    defaultMessage: "You don't have permission to view this page",
  },
  invoiceNumber: {
    id: 'app.common.invoiceOperating.invoiceNumber',
    defaultMessage: 'Invoice #{number}',
  },

  // loading
  loading: {
    id: 'app.common.loading',
    defaultMessage: 'Loading...',
  },
  // sale totals
  netSales: {
    id: 'app.common.sales.net',
    defaultMessage: 'Net sales',
  },
  discounts: {
    id: 'app.common.sales.discounts',
    defaultMessage: 'Discounts',
  },
  refunds: {
    id: 'app.common.sales.refunds',
    defaultMessage: 'Refunds',
  },
  taxes: {
    id: 'app.common.sales.taxes',
    defaultMessage: 'Taxes',
  },
  timeclock: {
    id: 'app.common.modules.timeclock',
    defaultMessage: 'Timeclock',
  },
  daySheet: {
    id: 'app.common.modules.daySheet',
    defaultMessage: 'Day Sheet',
  },
  expected: {
    id: 'app.common.sales.expected',
    defaultMessage: 'Expected',
  },
  actual: {
    id: 'app.common.actual',
    defaultMessage: 'Actual',
  },
  variance: {
    id: 'app.common.variance',
    defaultMessage: 'Variance',
  },
  // Invoice
  cancelInvoiceBody: {
    id: 'app.modules.pos-kiosk.Invoice.cancelInvoiceBody',
    defaultMessage: 'Are you sure you want to cancel order?',
  },
  voidInvoiceBody: {
    id: 'app.modules.pos-kiosk.Invoice.voidInvoiceBody',
    defaultMessage:
      'Are you sure you want to void order? All funds will be refunded automatically.',
  },
  makeSnapshotsButton: {
    id: 'app.modules.invoice.report.buttons.make.snapshots',
    defaultMessage: 'Make Snapshot',
  },
  snapshotsButton: {
    id: 'app.modules.invoice.report.buttons.snapshots',
    defaultMessage: 'Snapshots',
  },
  // AlertConditions
  missing_personal_code: {
    id: 'app.common.AlertConditions.missingPersonalCode',
    defaultMessage: 'Missing personal code',
  },
  // modules
  frontDesk: {
    id: 'app.common.modules.frontDesk',
    defaultMessage: 'Front Desk',
  },
  privacyPolicy: {
    id: 'app.common.modules.privacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  crm: {
    id: 'app.common.modules.crm',
    defaultMessage: 'CRM',
  },
  ptcrm: {
    id: 'app.common.modules.ptcrm',
    defaultMessage: 'PT CRM',
  },
  booking: {
    id: 'app.common.modules.booking',
    defaultMessage: 'Booking',
  },
  dictionaries: {
    id: 'app.common.modules.dictionaries',
    defaultMessage: 'Dictionaries',
  },
  pos: {
    id: 'app.common.modules.pos',
    defaultMessage: 'POS',
  },
  kidZone: {
    id: 'app.common.modules.kidZone',
    defaultMessage: 'Kid Zone',
  },
  clubDivider: {
    id: 'app.common.modules.clubDivider',
    defaultMessage: 'clubDivider',
  },
  clubCorpDivider: {
    id: 'app.common.modules.clubCorpDivider',
    defaultMessage: 'clubCorpDivider',
  },
  posContent: {
    id: 'app.common.modules.posContent',
    defaultMessage: 'POS Content',
  },
  automation: {
    id: 'app.common.modules.automation',
    defaultMessage: 'Automation',
  },
  members: {
    id: 'app.common.modules.members',
    defaultMessage: 'Members',
  },
  reports: {
    id: 'app.common.modules.reports',
    defaultMessage: 'Reports',
  },
  permissions: {
    id: 'app.common.modules.permissions',
    defaultMessage: 'Permissions',
  },
  corporateSettings: {
    id: 'app.common.modules.corporateSettings',
    defaultMessage: 'Corporate Settings',
  },
  // statuses
  activeStatus: {
    id: 'app.common.statuses.active',
    defaultMessage: 'Active',
  },
  inactiveStatus: {
    id: 'app.common.statuses.inactive',
    defaultMessage: 'Inactive',
  },
  notTodayStatus: {
    id: 'app.common.statuses.notToday',
    defaultMessage: 'Not available today',
  },
  // Customer types
  guestCustomeStatusTitle: {
    id: 'app.common.customer.status.guest',
    defaultMessage: 'Guest',
  },
  activeMemberCustomeStatusTitle: {
    id: 'app.common.customer.status.active',
    defaultMessage: 'Active member',
  },
  frozenMemberCustomeStatusTitle: {
    id: 'app.common.customer.status.frozen',
    defaultMessage: 'Frozen member',
  },
  expiredMemberCustomeStatusTitle: {
    id: 'app.common.customer.status.expired',
    defaultMessage: 'Expired Member',
  },
  deletedMemberCustomeStatusTitle: {
    id: 'app.common.customer.status.deleted',
    defaultMessage: 'Deleted Member',
  },
  // snackbar
  notImplementedYet: {
    id: 'app.common.snackbar.notImplementedYet',
    defaultMessage: 'Not implemented yet.',
  },
  paymentProcessorTypeInCorporationIsDisabled: {
    id: 'app.common.snackbar.paymentProcessorTypeInCorporationIsDisabled',
    defaultMessage: 'Payment processor type is disabled. Configure it before adding credit card.',
  },
  // invoices
  sessions: {
    id: 'app.common.invoices.session',
    defaultMessage: '{count, plural, =0 {session} one {# session} other {# sessions}}',
  },
  noMembershipPackageSelected: {
    id: 'app.common.invoices.noMembershipPackageSelected',
    defaultMessage: 'No membership bundle selected',
  },
  salesPersonHeader: {
    id: 'app.common.salesPersonHeader',
    defaultMessage: 'Salesperson',
  },
  customerHeader: {
    id: 'app.common.customerHeader',
    defaultMessage: 'Customer',
  },
  registerHeader: {
    id: 'app.common.registerHeader',
    defaultMessage: 'Register',
  },
  invoiceHeader: {
    id: 'app.common.invoiceHeader',
    defaultMessage: 'Invoice',
  },
  amountHeader: {
    id: 'app.common.amountHeader',
    defaultMessage: 'Amount',
  },
  dateHeader: {
    id: 'app.common.dateHeader',
    defaultMessage: 'Date',
  },
  salesInfoHeader: {
    id: 'app.common.salesInfoHeader',
    defaultMessage: 'Sales info',
  },
  profileHeader: {
    id: 'app.common.profileHeader',
    defaultMessage: 'Profile',
  },
  myProfileHeader: {
    id: 'app.common.myProfileHeader',
    defaultMessage: 'My Profile',
  },
  invoicesTitle: {
    id: 'app.common.invoices.invoicesTitle',
    defaultMessage: 'Invoices',
  },
  // document-templates
  templateCreatedMessage: {
    id: 'modules.crm.templates.templateCreatedMessage',
    defaultMessage: 'New document template has been created',
  },
  templateUpdatedMessage: {
    id: 'modules.crm.templates.templateCreatedMessage',
    defaultMessage: 'Document template has been updated',
  },
  // Duration type options
  minutesOption: {
    id: 'app.common.options.minutes',
    defaultMessage: 'Minutes',
  },
  hoursOption: {
    id: 'app.common.options.hours',
    defaultMessage: 'Hours',
  },
  daysOption: {
    id: 'app.common.options.days',
    defaultMessage: 'Days',
  },
  weeksOption: {
    id: 'app.common.options.weeks',
    defaultMessage: 'Weeks',
  },
  beWeeksOption: {
    id: 'app.common.options.beWeeks',
    defaultMessage: 'Bi-Weeks',
  },
  monthsOption: {
    id: 'app.common.options.months',
    defaultMessage: 'Months',
  },
  quartersOptions: {
    id: 'app.common.options.quarters',
    defaultMessage: 'Quarters',
  },
  semiAnnuallyOption: {
    id: 'app.common.options.semiAnnually',
    defaultMessage: 'Semi-annually',
  },
  yearsOption: {
    id: 'app.common.options.years',
    defaultMessage: 'Years',
  },
  customOption: {
    id: 'app.common.options.custom',
    defaultMessage: 'Custom',
  },
  fromNowOption: {
    id: 'app.common.options.fromNowOption',
    defaultMessage: 'From now',
  },
  startingOnDateOption: {
    id: 'app.common.options.startingOnDateOption',
    defaultMessage: 'Starting on date',
  },
  forRangeOption: {
    id: 'app.common.options.forRangeOption',
    defaultMessage: 'For period range',
  },
  // billing frequency options
  weeklyFrequencyOption: {
    id: 'app.common.options.weekly',
    defaultMessage: 'Weekly',
  },
  biWeeklyFrequencyOption: {
    id: 'app.common.options.biWeekly',
    defaultMessage: 'Bi-Weekly',
  },
  monthlyFrequencyOption: {
    id: 'app.common.frequency.options.monthly',
    defaultMessage: 'Monthly',
  },
  quarterlyFrequencyOption: {
    id: 'app.common.frequency.options.quarterly',
    defaultMessage: 'Quarterly',
  },
  semiAnnuallyFrequencyOption: {
    id: 'app.common.frequency.options.semiAnnually',
    defaultMessage: 'Semi-annually',
  },
  annuallyFrequencyOption: {
    id: 'app.common.frequency.options.annually',
    defaultMessage: 'Annually',
  },
  customFrequencyOption: {
    id: 'app.common.frequency.options.custom',
    defaultMessage: 'Custom',
  },
  // Scanners
  barcodeScannerForAutoCheckinsNotConnected: {
    id: 'app.common.scanners.barcodeScannerForAutoCheckinsNotConnected',
    defaultMessage: 'Barcode scanner for auto checkins not connected',
  },
  barcodeScannerIsNotConnected: {
    id: 'app.common.scanners.barcodeScannerIsNotConnected',
    defaultMessage: 'Barcode scanner is not connected',
  },
  magneticStripeIsNotConnected: {
    id: 'app.common.scanners.magneticStripeIsNotConnected',
    defaultMessage: 'Magnetic stripe is not connected',
  },
  deviceIsNotConnected: {
    id: 'app.common.scanners.deviceIsNotConnected',
    defaultMessage: 'Device is not connected',
  },
  barcodeScannerForAutoCheckinsConnected: {
    id: 'app.common.scanners.barcodeScannerForAutoCheckinsConnected',
    defaultMessage: 'Barcode scanner for auto checkins connected',
  },
  barcodeScannerConnected: {
    id: 'app.common.scanners.barcodeScannerConnected',
    defaultMessage: 'Barcode scanner connected',
  },
  cashDrawerConnected: {
    id: 'app.common.scanners.cashDrawerConnected',
    defaultMessage: 'Cash drawer connected',
  },
  magneticStripeConnected: {
    id: 'app.common.scanners.magneticStripeConnected',
    defaultMessage: 'Magnetic stripe connected',
  },
  waitingForScan: {
    id: 'app.common.scanners.waitingForScan',
    defaultMessage: 'Waiting for scan...',
  },
  contactTechnicalSupport: {
    id: 'app.common.scanners.contactTechnicalSupport',
    defaultMessage: 'If scanned barcode is not valid - please contact technical support.',
  },
  // others
  megabyte: {
    id: 'app.common.megabyte',
    defaultMessage: 'MB',
  },
  kilobyte: {
    id: 'app.common.kilobyte',
    defaultMessage: 'KB',
  },
  byte: {
    id: 'app.common.byte',
    defaultMessage: 'byte',
  },
  bytes: {
    id: 'app.common.bytes',
    defaultMessage: 'bytes',
  },
  multiple: {
    id: 'app.common.text.multiple',
    defaultMessage: 'Multiple',
  },
  selectedClubs: {
    id: 'app.common.text.selectedClubs',
    defaultMessage: 'Selected clubs',
  },
  opened: {
    id: 'app.common.text.opened',
    defaultMessage: 'Opened',
  },
  closed: {
    id: 'app.common.text.closed',
    defaultMessage: 'Closed',
  },
  cancelled: {
    id: 'app.common.text.cancelled',
    defaultMessage: 'Cancelled',
  },
  redeemed: {
    id: 'app.common.text.redeemed.status',
    defaultMessage: 'Redeemed',
  },
  unredeemed: {
    id: 'app.common.text.unredeemed.status',
    defaultMessage: 'Unredeemed',
  },
  missed: {
    id: 'app.common.text.missed.status',
    defaultMessage: 'Missed',
  },
  pending: {
    id: 'app.common.text.pending.status',
    defaultMessage: 'Pending',
  },
  confirmed: {
    id: 'app.common.text.confirmed.status',
    defaultMessage: 'Confirmed',
  },
  membership: {
    id: 'app.common.text.membership',
    defaultMessage: 'Membership',
  },
  cancelMembershipChip: {
    id: 'app.common.text.cancel.membership.chip',
    defaultMessage: 'Cancel Membership',
  },
  cancelBundleChip: {
    id: 'app.common.text.cancel.bundle.chip',
    defaultMessage: 'Cancel Bundle',
  },
  uploadHelper: {
    id: 'app.common.text.uploadHelper',
    defaultMessage: 'JPG, PNG, SVG.',
  },
  sidebarFooterText: {
    id: 'app.common.sidebar.footerText',
    defaultMessage: '© {year} Peak Payment.{br} All Rights Reserved.',
  },
  newMemberCommonTitle: {
    id: 'app.common.text.newMemberCommonTitle',
    defaultMessage: 'New Guest/Member',
  },
  noRecentCheckInsTitle: {
    id: 'app.common.text.noRecentCheckInsTitle',
    defaultMessage: 'No recent check-ins selected',
  },
  notSpecified: {
    id: 'app.common.text.notSpecified',
    defaultMessage: 'Not specified',
  },
  leavePageWarning: {
    id: 'app.common.warning.leavePageWarning',
    defaultMessage: 'Discard entered data?',
  },
  copyToClipboardSuccess: {
    id: 'app.common.clipboard.success',
    defaultMessage: 'Copied to the clipboard.',
  },
  copyToClipboardError: {
    id: 'app.common.clipboard.error',
    defaultMessage: 'Failed to copy to the clipboard.',
  },
  noItemsFound: {
    id: 'app.common.plug.noItemsFound',
    defaultMessage: 'No items found',
  },
  comingSoonBundles: {
    id: 'app.common.text.comingSoonBundles',
    defaultMessage: 'Coming soon bundles',
  },
  noPermissions: {
    id: 'app.common.text.noPermissions',
    defaultMessage: 'No permissions - please contact administrator',
  },
  salespersonRequiredWarning: {
    id: 'app.common.text.comingSoonBundles',
    defaultMessage: 'Salesperson is required.',
  },
  outOfStockText: {
    id: 'app.common.text.outOfStockText',
    defaultMessage: 'Out Of Stock',
  },
  alreadyInCartText: {
    id: 'app.common.text.alreadyInCartText',
    defaultMessage: 'Already In Cart',
  },
  putOnAccountAmount: {
    id: 'app.common.text.putOnBalance',
    defaultMessage: "{amount} will be put on member's account balance",
  },
  bundleCanNotBeSoldInSelectedClub: {
    id: 'app.common.text.bundleCanNotBeSoldInSelectedClub',
    defaultMessage: `This bundle can't be sold in selected club`,
  },
  bundleCanNotBeSoldByEmployee: {
    id: 'app.common.text.bundleCanNotBeSoldByEmployee',
    defaultMessage: `This bundle can't be sold by employee`,
  },
  noDataFound: {
    id: 'app.common.text.noDataFound',
    defaultMessage: 'No data found!',
  },
  now: {
    id: 'app.common.text.now',
    defaultMessage: 'now',
  },
  ownerNameLabel: {
    id: 'app.common.label.ownerNameLabel',
    defaultMessage: 'Owner Name',
  },
  routingNumberLabel: {
    id: 'app.common.label.routingNumberLabel',
    defaultMessage: 'Routing №',
  },
  accountNumberLabel: {
    id: 'app.common.label.accountNumberLabel',
    defaultMessage: 'Account №',
  },
  checkNumberLabel: {
    id: 'app.common.label.checkNumberLabel',
    defaultMessage: 'Check №',
  },
  typeLabel: {
    id: 'app.common.label.typeLabel',
    defaultMessage: 'Type',
  },
  checkingSavingsLabel: {
    id: 'app.common.label.checkingSavingsLabel',
    defaultMessage: 'Checking/Savings',
  },
  addCheckingSavingsLabel: {
    id: 'app.common.label.addCheckingSavingsLabel',
    defaultMessage: 'Add Checking/Savings',
  },
  addCreditCardLabel: {
    id: 'app.common.label.addCreditCardLabel',
    defaultMessage: 'Add Credit Card',
  },
  savingsLabel: {
    id: 'app.common.label.savingsLabel',
    defaultMessage: 'Savings',
  },
  checkingLabel: {
    id: 'app.common.label.checkingLabel',
    defaultMessage: 'Checking',
  },
  currentLabel: {
    id: 'app.common.label.currentLabel',
    defaultMessage: 'Current',
  },
  nosLabel: {
    id: 'app.common.label.nosLabel',
    defaultMessage: 'NOS',
  },
  applyChangeAmountToAccountLabel: {
    id: 'app.common.label.nosLabel',
    defaultMessage: 'Apply Change Amount to Account Credit',
  },
  yourCart: {
    id: 'app.common.titles.yourCart',
    defaultMessage: 'Your cart',
  },
  communication: {
    id: 'app.common.modules.communication',
    defaultMessage: 'Communication',
  },
  neverLabel: {
    id: 'app.common.label.never',
    defaultMessage: 'Never',
  },
  feeRatesLabel: {
    id: 'app.common.labels.feeRatesLabel',
    defaultMessage: 'Fee Rates',
  },
  accountCreditHistoryTitle: {
    id: 'app.common.titles.accountCreditHistoryTitle',
    defaultMessage: 'Account Credit History',
  },
  debitLabel: {
    id: 'app.common.label.debitLabel',
    defaultMessage: 'Debit',
  },
  creditLabel: {
    id: 'app.common.label.creditLabel',
    defaultMessage: 'Credit',
  },
});
