import { IObject } from 'common/interfaces/common';
import { colors } from 'styles/variables';

export function getScrollBarBGColor(color: string): IObject {
  return {
    '&::-webkit-scrollbar-track': {
      backgroundColor: color,
    },
    '&::-webkit-scrollbar-thumb': {
      borderColor: color,
    },
  };
}

export function getScrollBarBorderRadius(borderRadius: number | string = 4): IObject {
  return {
    '&::-webkit-scrollbar-track': {
      borderRadius,
    },
    '&::-webkit-scrollbar-corner': {
      borderRadius,
    },
  };
}

export function getScrollBarBGGray50(): IObject {
  return getScrollBarBGColor(colors.gray50);
}

export function getScrollBarGeneralBorderRadius(): IObject {
  return getScrollBarBorderRadius(4);
}
